import React, { useState } from 'react'
import GardenToast from '../../../GardenToast'
import GardenLogo from '../../../../images/garden_patent_logo.png'

export default function TopBar({ className }) {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <div className={className}>
            <div className='w-full h-full p-4 flex items-center border-b border-gray-300'>
                <img className='h-[1.25rem]' alt='Garden Logo' src={GardenLogo} />
            </div>
            <GardenToast isOpen={isOpen} setIsOpen={setIsOpen} message={'Document link copied to clipboard'} type={'notification'} />
        </div>
    )
}
