import axios from 'axios'
import { convertResponseToJson } from '.'

export async function newClaimsSession(title, description) {
    return axios.post('/claims/sessions/new', {
        title: title,
        description: description
    }, {
        transformResponse: convertResponseToJson
    }).then((response) => {
        return response.data
    }).catch((error) => {
        throw new Error(error)
    })
}

export async function getClaimsSessionsMetadata() {
    return axios.get('/claims/sessions/metadata', {
        transformResponse: convertResponseToJson
    }).then((response) => {
        return response.data
    }).catch((error) => {
        throw new Error(error)
    })
}

export async function getClaimsSessionDocument(claimsSessionId) {
    return axios.get(`/claims/${claimsSessionId}/document`, {
        transformResponse: convertResponseToJson
    }).then((response) => {
        return response.data.document
    }).catch((error) => {
        throw new Error(error)
    })
}

export async function downloadClaimsSessionDocument(claimsSessionId) {
    return axios({
        url: `/claims/${claimsSessionId}/download`,
        method: 'GET',
        responseType: 'blob', // Important: indicates that the server response is a binary file
    }).then((response) => {
        return response.data
    }).catch((error) => {
        throw new Error(error);
    })
}

export function connectToClaimsWebsocket(claimsSessionId, token, setSession) {
    try {
        const socket = new WebSocket(`${process.env.REACT_APP_WEBSOCKET_BASE_URL}/claims/${claimsSessionId}/ws`)
        socket.onopen = (e) => {
            console.log('Claims Socket Open')
            socket.send(token)
        }
        socket.onmessage = (e) => {
            onMessage(e.data, setSession, claimsSessionId)
        }
        socket.onerror = (e) => {
            console.error('Claims Socket Error')
        }
        socket.onclose = (e) => {
            console.log('Claims Socket Close')
        }
        return socket
    } catch (error) {
        throw new Error(error)
    }
}

function onMessage(data, setSession, claimsSessionId) {
    let serverMessage = null
    try {
        serverMessage = JSON.parse(data)
    } catch (error) {
        console.error(error)
        return null
    }

    console.log('Claims Socket Message', serverMessage)

    if (serverMessage.type === 'document') {
        setSession((session) => ({
            ...session,
            doc: {
                sections: serverMessage.document.sections.map((section) => ({
                    title: section.title,
                    content: section.content,
                    loadingMessage: section.loading_message
                })),
                loadingMessage: serverMessage.document.loading_message
            }
        }))
    }
    else if (serverMessage.type === 'finish') {
        setSession((session) => ({
            ...session,
            loadingMessage: null,
            processing: false
        }))
    }
}