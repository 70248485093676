import GardenLogo from '../images/garden_patent_logo.png';
import { useContext } from 'react';
import { AuthContext } from '../components/AuthProvider';

export default function LoginPage() {
    const { handleLogin } = useContext(AuthContext)
    return (
        <div className="flex min-h-screen items-center justify-center bg-primary-background">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <img
            className="mx-auto h-10 w-auto"
            src={GardenLogo}
            alt="Garden company logo"
            />
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-primary-foreground font-inter">
            Sign in to your account
            </h2>

            <form className="mt-10 space-y-6" onSubmit={handleLogin}>
            <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-primary-foreground font-inter">
                Email address
                </label>
                <div className="mt-2">
                <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-primary-foreground shadow-sm ring-1 ring-inset ring-dark-gray placeholder:text-dark-gray focus:ring-2 focus:ring-inset focus:ring-garden-green sm:text-sm sm:leading-6"
                />
                </div>
            </div>

            <div>
                <div className="flex items-center justify-between">
                <label htmlFor="password" className="block text-sm font-medium leading-6 text-primary-foreground font-inter">
                    Password
                </label>
                <div className="text-sm">
                    <a href="/#" className="font-semibold text-garden-green hover:text-secondary-green">
                    Forgot password?
                    </a>
                </div>
                </div>
                <div className="mt-2">
                <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-primary-foreground shadow-sm ring-1 ring-inset ring-dark-gray placeholder:text-dark-gray focus:ring-2 focus:ring-inset focus:ring-garden-green sm:text-sm sm:leading-6"
                />
                </div>
            </div>

            <div>
                <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-garden-green px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-secondary-green focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-garden-green"
                >
                Sign in
                </button>
            </div>
            </form>

            <p className="mt-10 text-center text-sm text-dark-gray">
            Not a user?{' '}
            <a href="/#" className="font-semibold leading-6 text-garden-green hover:text-secondary-green">
                Sign up for our waitlist
            </a>
            </p>
        </div>
        </div>
    )
}