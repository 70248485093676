import React, { useRef, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { ArrowDownOnSquareIcon } from '@heroicons/react/24/outline'
// import { GlobeAltIcon, MagnifyingGlassIcon } from '@heroicons/react/24/solid'
import { PulseLoader } from 'react-spinners'
import { ClaimsSessionContext } from '../../../../contexts'
import { downloadClaimsSessionDocument } from '../../../../utils/api'

// function IntroDescriptionCard({
//     icon,
//     title,
//     description
// }) {
//     return (
//         <div className='flex flex-row items-center p-8 space-x-5 bg-secondary-background rounded-2xl w-full h-auto'>
//             <div className='w-20 h-20 bg-washed-green rounded-full flex flex-shrink-0 items-center justify-center'>
//                 {icon}
//             </div>
//             <div className='flex flex-col space-y-2 items-baseline'>
//                 <h2 className='text-garden-green font-intro-card-title text-intro-card-title leading-intro-card-title font-title-weight'>
//                     {title}
//                 </h2>
//                 <p className='text-dark-gray font-intro-card-subtitle text-intro-card-subtitle leading-intro-card-subtitle font-subtitle-weight'>
//                     {description}
//                 </p>
//             </div>
//         </div>
//     )
// }

// function Welcome () {
//     return (
//         <div className='flex flex-col items-center gap-4'>
//             <div className='font-spaceGrotesk text-welcome-text leading-welcome-text text-primary-foreground flex items-center order-0 flex-none'>
//                 Welcome to Garden
//             </div>
//             <div className='font-inter text-welcome-text-subtitle leading-welcome-text-subtitle text-dark-gray font-subtitle-weight items-center order-0 flex-none text-center '>  {/* add this line */}
//                 Garden helps you create patent-specific legal language in seconds, here’s a few ways you can get started!
//             </div>
//             <IntroDescriptionCard
//                 icon={<MagnifyingGlassIcon className='h-8 w-8 text-garden-green' />}
//                 title='Preview Mode'
//                 description='Experience the early version, intended to highlight potential applications and gather your invaluable input.'
//             />
//             <IntroDescriptionCard
//                 icon={<GlobeAltIcon className='h-8 w-8 text-garden-green' />}
//                 title='Drafting Wizard'
//                 description='Our system instantly transforms claim structures into a comprehensive patent application.'
//             />
//         </div>
//     )
// }

function AiThinkingMessage({ children }) {
    return (
        <div className='flex self-start flex-row gap-2 py-2 px-4 rounded-md text-xs font-inter font-bold bg-washed-green'>
            <div className='flex border rounded-full items-center justify-center h-[1rem] w-[1rem] pb-[0.3em] font-spaceGrotesk text-[0.66rem] leading-[1rem] font-semibold bg-garden-green text-washed-green border-gray-300'>g</div>
            {children}
            <PulseLoader size='0.15rem' cssOverride={{alignItems: 'center'}} />
        </div>
    )
}

export default function Document({
    className
}) {
    const scrollRef = useRef(null)

    const { session: { claimsSessionId, doc } } = useContext(ClaimsSessionContext)

    const { id } = useParams()

    // useEffect(() => {
    //     if (scrollRef?.current) {
    //         const HTMLCollection = scrollRef.current.children
    //         if (HTMLCollection.length > 0) {
    //             HTMLCollection.item(HTMLCollection.length - 1).scrollIntoView({behavior: 'smooth', block: 'end'})
    //         }
    //     }
    // }, [doc])

    function handleDownload() {
        downloadClaimsSessionDocument(claimsSessionId).then((data) => {
            // Create a blob URL representing the binary file
            let fileURL = window.URL.createObjectURL(new Blob([data]));
                    
            // Create a link element, click it to download the file, and then remove it
            let link = document.createElement('a');
            link.href = fileURL;
            link.download = `Draft-${id}.docx`;
            link.click();
            link.remove();
        }).catch((error) => {
            console.error(error)
        })
    }

    return (
        <div className={className}>
            <div className='w-full h-full flex flex-col border-l border-gray-300'>
                <div className='w-full flex-grow min-h-0 p-[2rem] overflow-y-scroll bg-[#DCDCDC]'>
                    <div className='w-full min-h-full px-[2.5rem] py-[4rem] font-serif bg-white'>
                        <div ref={scrollRef} className='my-4'>
                            {!!doc.loadingMessage
                                ? <AiThinkingMessage>{doc.loadingMessage}</AiThinkingMessage>
                                : doc.sections.map((section, index) => (
                                    <div key={index}>
                                        <h2 className='font-bold text-lg whitespace-pre-wrap'>{section.title}</h2>
                                        {!!section.loadingMessage
                                            ? <AiThinkingMessage>{section.loadingMessage}</AiThinkingMessage>
                                            : <p className='text-sm whitespace-pre-wrap'>{section.content}</p>
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className='w-full flex flex-shrink-0 justify-end items-center basis-[3rem] border-t border-[#DCDCDC] bg-secondary-background'>
                    <button
                        className='flex gap-1 mr-6 text-garden-green'
                        onClick={handleDownload}
                    >
                        <ArrowDownOnSquareIcon className='h-[1.5rem] w-[1.5rem]' />
                        <p className='font-spaceGrotesk font-bold'>Download document</p>
                    </button>
                </div>
            </div>
        </div>
    )
}
