import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { ClaimsSessionContext } from '../../../contexts'
import {
    getClaimsSessionDocument,
    connectToClaimsWebsocket
} from '../../../utils/api'
import TopBar from './TopBar'
import Document from './Document'
import Chat from './Chat'


export default function Session({
    className
}) {
    const { session, setSession } = useContext(ClaimsSessionContext)
    const { id } = useParams()

    useEffect(() => {
        let stop = false
        let socket
        async function setupSession() {
            let doc
            try {
                doc = await getClaimsSessionDocument(session.claimsSessionId)
            } catch (error) {
                throw new Error(error)
            }
            if (stop) {return}

            try {
                socket = connectToClaimsWebsocket(session.claimsSessionId, localStorage.getItem(id), setSession)
            } catch (error) {
                throw new Error(error)
            }

            setSession((session) => ({
                ...session,
                doc: {
                    sections: doc.sections.map((section) => ({
                        title: section.title,
                        content: section.content,
                        loadingMessage: section.loading_message
                    })),
                    loadingMessage: doc.loading_message
                },
                socket: socket
            }))
        }
        function clean() {
            stop = true
            if (socket) {
                socket.close()
            }
        }

        try {
            if (session.claimsSessionId) {
                setupSession()
            }
        } catch (error) {
            console.error(error)
        }
        return clean    
    }, [session.claimsSessionId, id, setSession])

    return (
        <div className={className}>
            <div className='w-full h-full flex flex-col min-h-0'>
                <TopBar className='basis-[5rem]' />
                <div className='flex flex-grow min-h-0'>
                    <Chat className='basis-[40%] flex-shrink-0 min-w-0' />
                    <Document className='basis-[60%] flex-shrink-0' />
                </div>
            </div>
        </div>
    )
}