import React from 'react'
import { XMarkIcon, ShieldExclamationIcon } from '@heroicons/react/20/solid'

const types = {
    'notification': 'bg-white',
    'error': 'bg-[#FFF2F2]'
}

export default function GardenToast({
    isOpen,
    setIsOpen,
    message,
    type = 'notification'
}) {
    if (!isOpen) {
        return null
    }

    return (
        <div className={`fixed flex left-1/2 bottom-6 -translate-x-1/2 z-50 p-4 gap-4 rounded-lg border shadow-[0_4px_4px_0_rgba(0,0,0,0.05)] border-[#DCDCDC] ${types[type]}`}>
            {type === 'error' && <ShieldExclamationIcon className='h-[1.25rem] w-[1.25rem] text-negative' />}
            <p className='font-inter font-semibold text-sm text-primary-foreground'>{message}</p>
            <button onClick={() => {setIsOpen(false)}}>
                <XMarkIcon className='h-[1.25rem] w-[1.25rem] text-dark-gray' />
            </button>
        </div>
    )
}