import React, { useContext, useState } from 'react'
import { ArrowRightIcon } from '@heroicons/react/24/outline'
import { ClaimsSessionContext } from '../../../../contexts'

export default function Chat({
    className
}) {
    const {
        session: {
            socket,
            processing
        },
        setSession
    } = useContext(ClaimsSessionContext)

    const [value, setValue] = useState('')

    function onChange(event) {
        setValue(event.target.value)
    }

    function handleSend() {
        if (!value) {
            return
        }
        setSession((session) => ({
            ...session,
            processing: true
        }))
        socket.send(JSON.stringify({
            'claims_submission': value
        }))
    }

    function handleKeyDown(e) {
        if(e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault()
            handleSend()
        }
    }

    return (
        <div className={className}>
            <div className='w-full h-full px-[1rem] py-[1rem]'>
                <div className='w-full h-full flex border rounded-2xl pl-[0.5rem] pr-[0.75rem] py-[0.5rem] border-[#DCDCDC] bg-white shadow-[0_4px_4px_0_rgba(0,0,0,0.05)]'>
                    <textarea
                        className='flex-grow resize-none border-0 py-[0.25rem] pl-[0.5rem] pr-[2.5rem] mr-[-2.5rem] garden-scrollbar text-primary-foreground placeholder:text-dark-gray focus:ring-0'
                        autoComplete='off'
                        placeholder='Input your claims'
                        type='text'
                        value={value}
                        onChange={onChange}
                        onKeyDown={handleKeyDown}                  
                    />
                    <div className='flex flex-shrink-0 h-[5rem] w-[2rem] justify-end self-center'>
                        {processing === false
                            ? <button
                                className='block h-full w-full rounded-full px-[0.25rem] bg-black'
                                onClick={handleSend}
                            >
                                <ArrowRightIcon className='stroke-white stroke-[2px]' />
                            </button>
                            : <div className='flex h-full w-full rounded-full px-[0.25rem] bg-dark-gray'>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}