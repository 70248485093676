import React, { useContext, useState, useEffect } from 'react'
import Session from './Session'
import { ClaimsSessionContext } from '../../contexts'
import {
    newClaimsSession,
    getClaimsSessionsMetadata
} from '../../utils/api'

export default function Claims({
    className
}) {
    const {session: sessionDefaults} = useContext(ClaimsSessionContext)

    const [session, setSession] = useState(sessionDefaults)

    useEffect(() => {
        let stop = false
        async function setSessionMetadata() {
            let sessionsMetadata
            try {
                sessionsMetadata = await getClaimsSessionsMetadata()
            } catch (error) {
                throw new Error(error)
            }
        
            let sessionMetadata
            if (stop) {return}
            if (sessionsMetadata.length === 0) {
                try {
                    sessionMetadata = await newClaimsSession('Preview Title', 'Preview Description')
                } catch (error) {
                    throw new Error(error)
                }
            }
            else {
                sessionMetadata = sessionsMetadata[0]
            }
        
            if (stop) {return}
            setSession((session) => ({
                ...session,
                claimsSessionId: sessionMetadata.claims_session_id,
                title: sessionMetadata.title,
                description: sessionMetadata.description,
                status: sessionMetadata.status,
                created: sessionMetadata.created,
                modified: sessionMetadata.modified
            }))
        }

        function clean() {
            stop = true
        }

        if (!session.claimsSessionId) {
            try {
                setSessionMetadata()
            } catch (error) {
                console.error(error)
            }
        }

        return clean
    }, [session.claimsSessionId, setSession])

    return (
        <ClaimsSessionContext.Provider value={{session: session, setSession: setSession}}>
            <div className={className}>
                {session.claimsSessionId !== null &&
                    <Session
                        className='w-full h-full min-h-0'
                    />
                }
            </div>
        </ClaimsSessionContext.Provider>
    )
}