import React from 'react'
import { createContext } from 'react';
import { useNavigate, Navigate, useLocation } from 'react-router-dom';
import axios from 'axios';

export const AuthContext = createContext(null)

export function AuthProvider({ children }) {
    const navigate = useNavigate()
    const location = useLocation()

    function handleLogin(e) {
        e.preventDefault()
        const form = e.target
        const formData = new FormData(form)
        const formJson = Object.fromEntries(formData.entries());

        axios.post('/user/login', {
            username: formJson.email,
            password: formJson.password
        }, {
            headers: {'Content-Type': 'multipart/form-data'}
        }).then((response) => {
            const username = formJson.email.toString()
            const token = response.data?.token || null
            if (token) {
                localStorage.setItem(username, token)
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
                navigate(location.state?.from?.pathname || `/draft/${username}`) // TODO: navigate to a page where the user can select their flow
            } else {
                console.error('Response missing token')
            }
        }).catch((error) => {
            console.error(error)
        })
    }    

    function handleLogout() {
        delete axios.defaults.headers.common['Authorization']
        navigate(location.state?.from?.pathname || '/login')
    }

    const value = {
        handleLogin: handleLogin,
        handleLogout: handleLogout
    }

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}

export function ProtectedRoute({ children }) {
    const location = useLocation()
    if (!axios.defaults.headers.common.Authorization) {
        const token = sessionStorage.getItem('token')
        if (token) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem('token')}`
        } else {
            return <Navigate to='/login' replace state={{ from: location }}/>
        }
    }

    return children
}