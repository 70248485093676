import React, { useEffect } from 'react'
import axios from 'axios'
import { createBrowserRouter, RouterProvider, redirect, useLocation } from 'react-router-dom'
import LoginPage from './pages/LoginPage'
import { AuthProvider, ProtectedRoute } from './components/AuthProvider'
import SignupPage from './pages/SignupPage'
import { analytics } from '../src/utils/firebase'
import Claims from './components/Claims'

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL

const router = createBrowserRouter([
    {
        path: '/',
        element: <></>,
        loader: async () => {
            return redirect('/login')
        }
    },
    {
        path: '/login',
        element: (
            <AuthProvider>
                <LoginPage />
            </AuthProvider>
        )
    },
    {
        path: '/signup',
        element: (
            <AuthProvider>
                <SignupPage />
            </AuthProvider>
        )
    },
    {
        path: '/draft',
        loader: async () => {
            return redirect('/login')
        }
    },
    {
        path: '/draft/:id',
        element: (
            <AuthProvider>
                <ProtectedRoute>
                    <Claims className='h-screen w-screen border border-gray-300' />
                </ProtectedRoute>
            </AuthProvider>
        )
    }
])
const PageviewLogger = () => {
    const location = useLocation()

    useEffect(() => {
        if (location.pathname !== '/') {
            analytics.logEvent('page_view', {
                page_path: location.pathname
            })
        }
    }, [location])

    return null
}
const App = () => {
    return (
        <RouterProvider router={router}>
            <PageviewLogger />
        </RouterProvider>
    )
}

export default App
