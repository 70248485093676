// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCqGIxzWVt8xx7TnZciXxPT_0mpG2ELyCc",
  authDomain: "garden-intelligence.firebaseapp.com",
  projectId: "garden-intelligence",
  storageBucket: "garden-intelligence.appspot.com",
  messagingSenderId: "330813239015",
  appId: "1:330813239015:web:18bd2b24068d94b726bc42",
  measurementId: "G-SJYB0YLJLJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
