import { createContext } from 'react'

export const ClaimsSessionContext = createContext({
    session: {
        claimsSessionId: null,
        title: null,
        description: null,
        status: null,
        created: null,
        modified: null,
        doc: {
            sections: [],
            loadingMessage: null
        },
        loadingMessage: null,
        socket: null,
        processing: false
    },
    setSession: () => {}
})
